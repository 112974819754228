import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button, Row, Col, Table, Card, Modal } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import CommonFlex from '../components/commonFlex'
import Footer from '../components/footer'
import CustomerInfos from './../components/CustomerInfos'
import { bdstatistics, apiURL, mqStatistics, GetQueryString } from '../components/census'

import './airedu.css'
import './../assets/css/price.css'
import CustomerInfosLink from "../components/CustomerInfosLink"

export default () => {
  // const [ data, setData ] = React.useState([])
  const [ showModal, setShowModal ] = React.useState({ show: false, url: null })
  const [ show, setShow ] = React.useState(false)

  const id = GetQueryString('id') || 'v4'

  const data1 = useStaticQuery(graphql`
    {
      allStrapiSystemUpdate (sort: {order: DESC, fields: time}) {
        nodes {
          url
          updated_at
          title
          time
          tag_name
          strapiId
          published_at
          linux_name
          id
          desc
          created_at
          version
        }
      }
    }
  `)

  const systemData = data1?.allStrapiSystemUpdate?.nodes
  const versionData = systemData?.length ? systemData.filter(v => v?.version == id) : []
  const latestRecordsMap = new Map();
  versionData.forEach(item => {
    const currentLatest = latestRecordsMap.get(item.title);
    if (!currentLatest || new Date(item.published_at) > new Date(currentLatest.published_at)) {
      latestRecordsMap.set(item.title, item);
    }
  })
  let data = Array.from(latestRecordsMap.values())
  if(id == 'v4') {
    data = data.filter(v => (v.title != 'Windows v4.0pm2版本')).filter(item => item?.linux_name != 'Linux')
  }

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
    if(id) {
      // fetch(`${apiURL}/system-updates?_sort=time:DESC&version=${id}`, {
      //   headers: { 'content-type': 'application/json' },
      // })
      //   .then((res) => {
      //     if (res.status == 200) {
      //       return res.json()
      //     }
      //   })
      //   .then(json => {
      //     const newData = json
      //     const latestRecordsMap = new Map();
      //     newData.forEach(item => {
      //       const currentLatest = latestRecordsMap.get(item.title);
      //       if (!currentLatest || new Date(item.published_at) > new Date(currentLatest.published_at)) {
      //         latestRecordsMap.set(item.title, item);
      //       }
      //     })
      //     let resultArray = Array.from(latestRecordsMap.values())
      //     if(id == 'v4') {
      //       resultArray = resultArray.filter(v => (v.title != 'Windows v4.0pm2版本')).filter(item => item?.linux_name != 'Linux')
      //     }
      //     setData(resultArray)
      //   })
      //   .catch(err => console.log(err))
    }
  }, [ id ])

  const convertUrl = function (url) {
    // https://airiot.link/v4/AIRIOT_Setup_V4_20240812.exe => /installer/v4/AIRIOT_Setup_V4_20240812.exe
    // 使用正则表达式匹配版本号和文件名
    // const regex = /v(\d+)\/([^\/]+)$/;
    // const match = url.match(regex);

    // if (!match) {
    //   return '/installer/undefined';
    // }

    // return `/installer/v${match[1]}/${match[2]}`;
    return url
  }

  return (
    <div>
      <div className="airedu down-index">
        <div className="home-bg airedu-bg" style={{ backgroundImage:`url(${require("./../images/airedu-banner.png")})`, height: '31.25rem' }}>
          <Layout>
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
            <div style={{ display: 'flex' }}>
              <div className="plat-jumbotron">
                <h1 className="plat-title" style={{ marginTop: '7.5rem', fontSize: '2.5rem' }}>公有云、私有云、边缘层</h1>
                <div className="plat-desc" style={{ fontSize: '1.25rem', marginTop: 0 }}>提供多种安装部署方式</div>
              </div>
            </div>
          </Layout>
        </div>
      </div>
      
      <Container className="content-box edu-contonier">
        {/* <CommonFlex animate="animate1" title="AIRIOT的优势与特点" desc="驱动丰富、功能完备、部署灵活"></CommonFlex>
        <div className="flex-ul">
          {
            traitData.map((item, index) => {
              return (
                <div className={index !==0 ? addClass === index ? 'flex-ul-box ul-box-color' : 'flex-ul-box' : 'flex-ul-box'} 
                  onMouseEnter={()=> setAddclass(index)} onMouseLeave={()=>{setAddclass(999)}}>
                  <ul>
                    {
                      item.tr.map(val => {
                        return (
                          <li>{val}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              )
            })
          }
        </div> */}
        <CommonFlex title="版本下载" desc={id == 'v3' ? '' : `<a href="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnBkykBXVYWsDVaXRNxU3dAf" target="_blank" class="try-license">申请试用授权</a>`}></CommonFlex>
        {/* <Link className="table-version-more" to={`/aireduMore?id=${id}`}><div >历史版本-></div></Link> */}
        <Table hover size="sm">
          <thead>
            <tr>
              <th>系统</th>
              <th>版本</th>
              <th>支持系统版本</th>
              <th>更新时间</th>
              {/* <th>更新内容</th> */}
              <th>下载地址</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((val, index) => {
                return <tr key={index}>
                  <td>{val.title}</td>
                  <td>{val.tag_name}</td>
                  <td>{val.linux_name}</td>
                  <td>{val.time}</td>
                  {/* <td>{val.desc}</td> */}
                  {/* <td><a href={val.url}>下载</a></td> */}
                  <td style={{ cursor: 'pointer' }} onClick={() => { setShowModal({ ...showModal, show: true, url: convertUrl(val.url) }) }}>下载</td>
                  {/* <td style={{ cursor: "pointer" }} onClick={() => downPlat(val.url) }>下载</td> */}
                </tr>
              })
            }
          </tbody>
        </Table>
        <Row className="price-support-row">
          <Col>
            <div className="price-circe"></div>
            <Card className="price-support">
              <Card.Body>
                <Card.Title className="price-card-f">咨询与定制化服务</Card.Title>
                <Card.Text>为您提供咨询服务和定制化服务，满足您的需求</Card.Text>
                <div style={{ textAlign: 'center' }}>
                  <Button variant="primary" className="price-bth">
                    <Link to="/contact" className="nav-title">立即申请</Link>
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          show={showModal.show}
          onHide={() => setShowModal({ ...showModal, show: false })}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          className="down_modal"
        >
          <Modal.Body style={{ padding: '1.5rem 5rem' }}>
            <CustomerInfosLink type="down" url={showModal.url} changeModal={() => { setShowModal({ ...showModal, show:false }); setShow(true) }}/>
          </Modal.Body>
        </Modal>
      </Container> 
      <Footer style={{ marginTop: '120px' }}></Footer>
    </div>   
  )
}  

